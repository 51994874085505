import { Button, ButtonGroup, FormCheck, FormControl } from 'react-bootstrap'
import { Instance } from '../models/shared/Instance';
import { useState } from 'react';
const SPEED_OPTIONS = [
    { value: 1, icon: 'bi-chevron-double-left' },
    { value: 0.5, icon: 'bi-chevron-left' },
    { value: -0.5, icon: 'bi-chevron-right' },
    { value: -1, icon: 'bi-chevron-double-right' },
];
const FONT_OPTIONS = [
    { label: "<Default>", value: "" },
    { "label": "Arial", "value": "Arial, sans-serif" },
    { "label": "Verdana", "value": "Verdana, sans-serif" },
    { "label": "Georgia", "value": "Georgia, serif" },
    { "label": "Times New Roman", "value": "\"Times New Roman\", Times, serif" },
    { "label": "Courier New", "value": "\"Courier New\", Courier, monospace" }
]
interface Props {
    instance: Instance
    autoUpdateInstance: (data: Partial<Instance>) => void
}

function InstanceOptionsDesign(props: Props) {
    const { autoUpdateInstance, instance } = props
    const [customColors, setCustomColors] = useState(!!(instance.textColor || instance.backgroundColor))


    const speed = instance.speed || 1;
    const fontFamily = instance.fontFamily || '';

    const toggleCustomColors = () => {
        if (customColors) {
            autoUpdateInstance({ textColor: '', backgroundColor: '' })
        }

        setCustomColors(!customColors)
    }


    return <>
        <label>Speed</label>
        <div className="mb-3">
            <ButtonGroup>
                {SPEED_OPTIONS.map(speedOption => {
                    const isSelected = speedOption.value === speed;
                    return <Button key={speedOption.value}
                        size="sm"
                        onClick={() => autoUpdateInstance({ speed: speedOption.value })}
                        variant={isSelected ? 'primary' : "outline-primary"}>
                        <i className={`bi ${speedOption.icon}`}></i>
                    </Button>
                })}
            </ButtonGroup>
        </div>

        <label>Font</label>
        <div className="mb-3">

            <ButtonGroup>
                {FONT_OPTIONS.map(option => {
                    const isSelected = fontFamily === option.value;
                    return <Button key={option.value}
                        style={{ fontFamily: option.value }}
                        size="sm"
                        onClick={() => autoUpdateInstance({ fontFamily: option.value })}
                        variant={isSelected ? 'primary' : "outline-primary"}>
                        {option.label}
                    </Button>
                })}
            </ButtonGroup>
            <p className="text-muted">*Default font inherits font from target site</p>
        </div>


        <FormCheck
            type="switch"
            label="Full width"
            className="pointer"
            checked={!!instance.fullWidth}
            onChange={(e) => autoUpdateInstance({ fullWidth: e.target.checked })}
        />

        <FormCheck
            type="switch"
            label="Show source logo"
            className="pointer"
            checked={!!instance.showSourceLogo}
            onChange={(e) => autoUpdateInstance({ showSourceLogo: e.target.checked })}
        />
        <FormCheck
            type="switch"
            label="Set custom colors"
            className="pointer"
            checked={!!customColors}
            onChange={() => toggleCustomColors()}
        />

        {customColors && <div className='p-2 d-flex gap'>
            <div className='center'>
                <FormControl
                    onChange={(e) => autoUpdateInstance({ textColor: e.target.value })}
                    type="color"
                    defaultValue={instance.textColor || "#000"}
                />
                <label>Text color</label>
            </div>

            <div className='center'>
                <FormControl
                    onChange={(e) => autoUpdateInstance({ backgroundColor: e.target.value })}
                    type="color"
                    defaultValue={instance.backgroundColor || "#FFF"}
                />
                <label>Background color</label>
            </div>
        </div>}
    </>

}

export default InstanceOptionsDesign
