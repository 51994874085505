import { Button, ButtonGroup, FormCheck } from 'react-bootstrap'
import { DATE_FORMATS_ORDER, DATE_FORMAT_LABELS, DateFormat, Instance } from '../models/shared/Instance';

interface Props {
    instance: Instance
    autoUpdateInstance: (data: Partial<Instance>) => void
}

function InstanceOptionsContent(props: Props) {
    const { autoUpdateInstance, instance } = props


    const minRating = instance.minRating || 1;
    const dateFormat: DateFormat = instance.dateFormat || 'DD/MM/YYYY';

    return <>
        <label>Minimal rating</label>
        <div className="mb-3 d-flex align-items-center">
            <ButtonGroup>
                {[1, 2, 3, 4, 5].map(number => {
                    const isSelected = number === minRating;
                    return <Button size="sm" key={number}
                        onClick={() => autoUpdateInstance({ minRating: number })}
                        variant={isSelected ? 'primary' : "outline-primary"}>
                        {number}
                    </Button>
                }
                )}
            </ButtonGroup>
            <div className="ms-2">
                {[1, 2, 3, 4, 5].map(number => <i key={number} className={`bi ${number <= minRating ? 'bi-star-fill' : 'bi-star'}`}
                    style={{ color: '#fbbc04' }}
                />)}
            </div>
        </div>

        <label>Date format</label>
        <div className="mb-3">

            <ButtonGroup>
                {DATE_FORMATS_ORDER.map(format => {
                    const isSelected = format === dateFormat;
                    return <Button key={format}
                        size="sm"
                        onClick={() => autoUpdateInstance({ dateFormat: format })}
                        variant={isSelected ? 'primary' : "outline-primary"}>
                        {DATE_FORMAT_LABELS[format]}
                    </Button>
                })}
            </ButtonGroup>
        </div>

        <FormCheck
            type="switch"
            label="Show only reviews with text"
            className="pointer"
            checked={!!instance.textOnly}
            onChange={(e) => autoUpdateInstance({ textOnly: e.target.checked })}
        />
        <FormCheck
            type="switch"
            label="Random order"
            className="pointer"
            checked={!!instance.randomOrder}
            onChange={(e) => autoUpdateInstance({ randomOrder: e.target.checked })}
        />
    </>

}

export default InstanceOptionsContent
