import { Tab, Tabs } from 'react-bootstrap'
import { Instance } from '../models/shared/Instance';
import { useState } from 'react';
import InstanceOptionsContent from './InstanceOptionsContent';
import InstanceOptionsDesign from './InstanceOptionsDesign';
interface Props {
    instance: Instance
    autoUpdateInstance: (data: Partial<Instance>) => void
}

function InstanceOptions(props: Props) {
    const { autoUpdateInstance, instance } = props
    const [tab, setTab] = useState<'content' | 'design'>('content')

    return <>
        <Tabs className='mt-3 mb-2' activeKey={tab}
            onSelect={(k) => setTab(k as any)}>
            <Tab eventKey="content" title={'Content Options'}>
                <InstanceOptionsContent instance={instance} autoUpdateInstance={autoUpdateInstance} />
            </Tab>
            <Tab eventKey="design" title={"Design & Styling"}>
                <InstanceOptionsDesign instance={instance} autoUpdateInstance={autoUpdateInstance} />
            </Tab>
        </Tabs>
    </>
}

export default InstanceOptions
